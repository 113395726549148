


.headerWrapperCss{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    gap: 16px;
}

.headerWrapperCss>div{
    border-right: 2px solid #ECE3E3;;
}

.headerWrapperCss>div:last-child{
    border-right: none;
}

.headerWrapperCss>div{
    padding-left: 32px;
}

.headerWrapperCss>div:first-child{
    padding-left: 0px;
}

.mainTextCss{
    margin-top: 12px;
    margin-bottom: 15px;
}


@media only screen and (max-width: 1040px) {
    .headerWrapperCss{
        gap: 8px;
        align-items: flex-start;
    }

    .headerWrapperCss>div{
        padding-left: 12px;
    }
  }

  @media only screen and (max-width: 490px) {
    .headerWrapperCss{
        gap: 16px;
       grid-template-columns: 1fr 1fr;
    }

    .headerWrapperCss>div{
        padding-left: 0px;
    }
    .headerWrapperCss>div{
        border-right: none;
    }
    
  }
  