.bottomGapCss{
    margin-bottom: 20px;
}

.bottomGapCss:last-child{
    margin-bottom: 0px;
}

.buttonWrapperCss{
    margin-top: 10px;
    margin-bottom: 42px;
    display: flex;
    gap: 8px;
}