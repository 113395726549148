.centerTextCss{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 24px;
    margin-top: 20px;

}

.buttonWrapperCss{

    width: 100%;
    margin-bottom: 16px;
    margin-top: 16px;
}

.bannerInfoCss{
    margin-bottom: 16px;
}