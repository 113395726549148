.mediaControlWrapperCss{
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 24px;
}
.flexWrapCss{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.productListWrapperCss{
    display: grid;
    margin-top: 20px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    align-items: center;
    gap: 12px;
}

/* MyComponent.module.css */

.imageContainer {
    position: relative;
    display: inline-block;  
    overflow: hidden; /* Ensure that the image won't overflow its container */
    width: 100%; /* Make the container fill its parent's width */
    aspect-ratio: 1;
  }
  
  .toggleButtonWrapper {
    position: absolute;
   bottom: 10px;
   left: 10px;
  }

  .cardOverride {
    padding: 0; 
  }
  
  .image {
    width: 100%;
    height: 100%;
    object-fit: cover;

  }

  .dropdownWrapperCss{
    display: flex;
    flex-direction: row;
    gap: 8px;
  }

  .paginationWrapperCss{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  .emptyStateContainerCss {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center; 
    height: 100%;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Adjust the color and opacity as needed */
  }

