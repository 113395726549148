.subHeadingWrapperCss{
    margin-top: 8px;
}

.priceTextWrapCss{
    margin: 10px 0px;
}

.priceDescWrapperCss{
    margin-bottom: 10px;
}

.featureBulletPointsCss {
    list-style: none;
    margin: 0px;
    padding: 0px;
}

.featureWrapCss{
    margin-top: 20px;
}

.featureBulletPointsCss>li{
padding-bottom: 18px;
}

.lineThroughCss{
    text-decoration: line-through;
}

.discountBadgeCss{
margin-top: 16px;
}

.discountBadgeHiddenCss{
    margin-top: 16px;
    visibility: hidden
}
/* .discountWrapper{
    margin-top: 16px;
} */

.planWrapperCss{
    display: flex;
    flex-direction: row;
    gap: 32px;
  
}

.planItemCss{
    padding: 16px 32px;
    border-radius: 8px;
    background-color: var(--p-color-bg-surface-secondary);
    cursor: pointer;
   
    width: 500px;


}
.planItemCss:last-child{
    background-color:white;
    box-shadow: var(--p-shadow-100);
    border: 2px solid rgba(232, 84, 110, 1)

}

.planItemCss:hover{
transition: all 100ms ease-in;
   transform: scale(1.05);
}
