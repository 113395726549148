.dashboardWrapperCss {
  margin-bottom: 40px;
}

.enableDisableWrapperCss {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dashboardInnerWrapperCss {
  display: grid;
  grid-template-columns: 1fr 344px;
  gap: 16px;
}

.dividerGapCss {
  margin-top: 12px;
}

.headingWrapperCss {
  display: flex;
  gap: 12px;
  align-items: center;
  margin-top: 12px;
  margin-bottom: 4px;
  justify-content: space-between;
}

.subHeadingWrapperCss {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 16px;
}

.widgetBoxWrapperCss {
  min-height: 300px;
  max-height: 400px;
  overflow: scroll;
}

.dashboardDesktopWrapperCss {
  display: grid;
  grid-template-columns: 1fr;
}

.desktopWidgetBoxWrapperCss {
  min-height: 300px;
}

.gridContainer {
  display: grid;
  grid-template-columns: repeat(12, 1fr); /* 12-column grid */
  gap: 16px; /* Adjust the gap as needed */
  margin: 0 auto; /* Center the container */
}

.buttonGroupWrapperCss {
  margin-top: 8px;
}

.div1 {
  grid-column: span 8;
}

.div2 {
  grid-column: span 4;
}

.buttonsOnLargerScreenCss{
    display: block;
}

.buttonsOnSmallerScreenCss{
    display: none;
}
/* Extra Large Screens (xl) */

/* Large Screens (lg) */
@media only screen and (max-width: 1040px) {
  .div1 {
    grid-column: span 9;
  }

  .div2 {
    grid-column: span 3;
    height: 100%;
  }
}

/* Medium Screens (md) */
@media only screen and (max-width: 768px) {
  .div1 {
    grid-column: span 12;
    order: 2;
  }

  .div2 {
    grid-column: span 12;
    order: 1;
  }
  .headingWrapperCss {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .buttonGroupCss {
    margin-bottom: 12px;
  }
  .buttonsOnLargerScreenCss{
    display: none;
  }
  .buttonsOnSmallerScreenCss{
    display: block;
    margin-top: 18px;
  }
}

/* Small Screens (sm) */
@media only screen and (max-width: 490px) {
  .div1 {
    grid-column: span 12;
    order: 2;
  }

  .div2 {
    grid-column: span 12;
    order: 1;
  }
  .enableDisableWrapperCss{
    align-items: center;
    flex-direction: column;
  }
  .buttonsOnLargerScreenCss{
    display: none;
  }
  .buttonsOnSmallerScreenCss{
    display: block;
    margin-top: 18px;

  }
}

/* Extra Small Screens (xs) */
@media only screen and (max-width: 0px) {
  .div1,
  .div2 {
    grid-column: span 12;
  }
  .buttonsOnLargerScreenCss{
    display: none;
  }
  .buttonsOnSmallerScreenCss{
    display: block;
    margin-top: 8px;
  }
}
