.templateWrapperCss{
   display: grid;
   grid-template-columns: 1fr 1fr ;
   column-gap: 16px;
   margin-top: 12px;
   row-gap: 16px;

}

.imageWrapperCss{
   width: 100%;

}

.imageWrapperCss>img{
   width: 100%;
}

.boxWrapperCss{
display: flex;
align-items: center;
justify-content: flex-start;

border-radius: 12px;
padding:16px;
background: white;
box-shadow: 1px 0px 0px 0px rgba(0, 0, 0, 0.13) inset, -1px 0px 0px 0px rgba(0, 0, 0, 0.13) inset, 0px -1px 0px 0px rgba(0, 0, 0, 0.17) inset, 0px 1px 0px 0px rgba(204, 204, 204, 0.5) inset

}

.buttonWrapperCss{
   margin-top: 32px;
}