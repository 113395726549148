.tabsContainer {
  display: flex;
  gap: 8px;
}

.tab {
  background-color: white;
  padding: 6px 12px;
  border-radius: 6px;
  color: black;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.tab:hover {
  background-color: #00000014;
}

.active {
  background-color: #00000014;
  color: #303030;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
}

.tabContent {
  margin-top: 16px;
}

.textCss {
  color: #4a4a4a;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 600;
}

.iconTextWrapperCss {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.iconWrapperCss {
  margin-left: 4px;
}

.plusButtonWrapper {
  padding-top: 12px;
}
