.wrapContainerCss{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding:14px 16px;
   
    border-bottom: 1px solid var(--p-color-bg-surface-secondary-active);
}

.wrapContainerCss:first-child{
    padding-top: 22px;

}

.wrapContainerCss:last-child{
    padding-bottom: 22px;
}

.wrapContainerCss:hover{
    background-color: var(--p-color-bg-surface-hover);
}

.wrapTitleCss{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    cursor: pointer;
}

.formWrapperCss{
    margin-top: 10px;
    width: 100%;
}

.actionButtonWrapperCss{
    margin-top: 16px;
}

.forLargeScreenCss {
  display: none;
}

.forSmallerScreenCss {
  display: block;
}

@media (min-width: 65em) {
  .forLargeScreenCss {
    display: block;
  }

  .forSmallerScreenCss {
    display: none;
  }
}