.subHeadingWrapperCss{
  margin-top: 8px;
}
.faqCard {
    border-bottom: 1px solid #ccc;
    padding: 16px;
  }
  
  .faqHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }
  
  .faqBadge {
    margin-left: 8px;
  }
  
  .faqExpandIcon {
    margin-left: 8px;
    cursor: pointer;
  }
  
  .faqContent {
    padding-top: 16px;
  }

  .faqText{
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .faqCard:last-child{
    border-bottom: none;
  }


.videoWrapperOuter {
  max-width:1040px; 
  margin-left:auto;
  margin-right:auto;
}
.videoWrapperInner {
  float: none;
  clear: both;
  width: 100%;
  position: relative;
  padding-bottom: 50%;
  padding-top: 25px;
  height: 0;
}
.videoWrapperInner iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}