.widgetWrapperCss {
  min-height: 400px;
}

.childrenWrapperCss {
  margin-top: 24px;
}

.spinLoaderCss {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
  flex-direction: column;
}

.bannerWrapperCss {
  margin-top: 24px;
}

.expandButtonCss{
    cursor: pointer;
}
