.swatch {
  padding: 5px;
  background: #fff;
  border-radius: 1px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  display: inline-block;
  cursor: pointer;
}

.color {
  width: 36px;
  height: 18px;
  border-radius: 2px;
}

.popover {
  position: absolute;
  z-index: 1500;
  right: 20px;
  bottom: 50px;
}

.cover {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}


.parentWrapperCss{
    display: flex;
    flex-direction: column;
}
.parentWrapperCss>:first-child{
    margin-top: 5px;
    display: inline-block;
}