.dashboardWrapperCss{
    display: flex;
    flex-direction: column;
    gap: 16px;
    height: 90vh;
    width: 100%;
}



.loadingBar1{
    height: 20vh;
    width: 100%;
}

.loadingBar2{
    height: 80vh;
    width: 100%;
}