/* ImageList.module.css */

.imageList {
    display: flex;
    flex-wrap: wrap;
  }
  
  .imageCard {
    width: 300px;
    margin: 16px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }
  
  .imageSkeleton {
    width: 100%;
    height: 200px;
    background-color: #f3f3f3;
    border-radius: 10px;
    animation: autoFadeIn 1s ease-in-out;
  }
  
  .textContainer {
    padding: 16px;
  }
  
  .textSkeleton {
    width: 80%;
    height: 16px;
    margin-bottom: 8px;
    background-color: #f3f3f3;
  }
  
  .small {
    width: 40%;
  }
  
  @keyframes autoFadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  /* Set a different delay for each element */
  .autoFadeIn:nth-child(odd) {
    animation-delay: 0.5s;
  }
  
  .autoFadeIn:nth-child(even) {
    animation-delay: 1s;
  }
  