.stockContainer {
  padding: 10px 0px;
}

.stockCount {
  color: #b74745;
  font-weight: 600;
    font-size: 18px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.fireIcon {
  color: #f25f5c;
  font-weight: 600;
  
}

.remainingTextCss {
  color: #7ed321;
  font-weight: 600;
  font-size: 18px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
