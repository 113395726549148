
.parentWrapperCss{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    flex-direction: column;
    padding: 77px 0px;
}

.headingWrapperCss{
    margin-top: 24px;
}

.subHeadiingWrapperCss{
    margin-top: 8px;
}

.buttonWrapperCss{
    margin-top: 24px;
}