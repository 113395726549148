.itemWrapperCss{
    border-bottom: 1px solid #d6d6d6;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-right: 16px;
    padding-left: 28px;
}

.itemWrapperCss:first-child{
    margin-top: 4px;
}

.itemWrapperCss:last-child{
    border-bottom: none;
}

.toggleButtonWrapperCss{
    cursor: pointer;
}

.titleCardWrapperCss{
    border-bottom: 1px solid #d6d6d6;
    padding-bottom: 16px;
    margin: 0px -16px;
    padding-left: 16px;

}