.headingTextCss{
    margin-bottom: 0px;
}


@media only screen and (max-width: 745px) {
    .headingTextCss{
        margin-bottom: 12px;
    }
}
