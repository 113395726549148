.titleCardWrapperCss {
  border-bottom: 1px solid #d6d6d6;
  padding-bottom: 16px;
  margin: 0px -16px;
  padding-left: 16px;
}

.toggleButtonWrapperCss {
  margin-right: 20px;
}

.textContainerWrapperCss {
  margin-top: 15px;
}

.noteCss {
  margin: 16px 0px;
}

.stepsWrapperCss ul {
  margin: 10px;
  padding: 0px;
}

.inputWrapperCss {
  margin-top: 10px;
}

.inputLabelCss {
  margin-bottom: 10px;
}
